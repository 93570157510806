import React from "react";
import { connect } from "react-redux";
import { fetchSessionsByDates } from "../reducers";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { munvoBlue, munvoOrange } from "../utils/globalStyles";
import { createMuiTheme } from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import CircularIndeterminate from "../CircularLoading";
import DateFnsUtils from "@date-io/date-fns";
import { validateTimezone, toRequestTimestamp, apiToLocalTimezone } from "./TablesUtils"
import { MuiPickersUtilsProvider, DateTimePicker } from "material-ui-pickers";
import Tooltip from "@material-ui/core/Tooltip";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: munvoBlue
    }
  },
  tableCell80: {
    overflow: "auto",
    width: "90px",
    display: "block"
  },
  tableCell120: {
    overflow: "auto",
    width: "120px",
    display: "block"
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
        color: "black",
        backgroundColor: munvoOrange
      }
    }
  }
});

function createData(
  sessionId,
  phoneNumber,
  nodeId,
  active,
  sessionType,
  tupdated,
  scampaignId
) {
  return {
    sessionId,
    phoneNumber,
    nodeId,
    active,
    sessionType,
    tupdated,
    scampaignId
  };
}

let rows = [];
let sortedRows = [];
let columnsQueries = [];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  sortedRows = stabilizedThis.map(el => el[0]);
  return sortedRows;
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  { id: "sessionId", label: "ID", width: "100px" },
  { id: "phoneNumber", label: "Phone", width: "100px" },
  { id: "nodeId", label: "Node Id", width: "100px" },
  { id: "active", label: "Active", width: "100px" },
  { id: "tupdated", label: "Time", width: "100px" },
  { id: "sessionType", label: "Session Type", width: "100px" },
  { id: "scampaignId", label: "Campaign Id", width: "100px" }
];

function SessionTableHead(props) {
  const { order, orderBy, onRequestSort, callbackFilter } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const handleColumnQueryChange = rowId => event => {
    if (columnsQueries.find(x => x.column === rowId) !== undefined) {
      columnsQueries.find(x => x.column === rowId).query = event.target.value;
    } else {
      columnsQueries.push({ column: rowId, query: event.target.value });
    }
    callbackFilter();
  };
  return (
    <TableHead padding="5px">
      {headRows.map(row => (
        <TableCell
          key={row.id}
          align={"center"}
          sortDirection={orderBy === row.id ? order : false}
        />
      ))}
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={"center"}
            padding="none"
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              <TextField
                label={row.label}
                placeholder="Filter"
                value={
                  columnsQueries.find(x => x.column === row.id) !== undefined
                    ? columnsQueries.find(x => x.column === row.id).query
                    : ""
                }
                onChange={handleColumnQueryChange(row.id)}
              />
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
SessionTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
};

const SessionTableToolbar = props => {
  return (
    <Toolbar>
      <Typography variant="h5" id="tableTitle">
        <h5>Session History</h5>
      </Typography>
    </Toolbar>
  );
};

const SessionTable = props => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("sessionId");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [numberOfSessions, setnumberOfSessions] = React.useState(0);
  const [dateFrom, handleFromDateChange] = React.useState(
    setdefaultDate("from")
  );
  const [dateTo, handleToDateChange] = React.useState(setdefaultDate("to"));
  const [timezone, setTimezone] = React.useState("America/Toronto");
  const [isValidTimezone, setIsValidTimezone] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);

  //set the default date filter to today
  function setdefaultDate(type) {
    let temp = new Date();
    if (type === "from") {
      temp.setHours(0, 0, 0);
    } else if (type === "to") {
      temp.setHours(23, 59, 59);
    }
    return temp;
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  //if the expression is undefined return an empty string
  //else return the expression
  function notUndefined(expression) {
    if (expression !== undefined) {
      return expression;
    }
    return "";
  }

  //if x contains one of the queries {column: "", query:""} return true
  //constraint on columns are joined by and OR
  const filterFunction = x => {
    for (let i = 0; i < columnsQueries.length; i++) {
      if (
        x[columnsQueries[i].column] !== undefined &&
        columnsQueries[i].query !== "" &&
        !x[columnsQueries[i].column]
          .toString()
          .toLowerCase()
          .includes(columnsQueries[i].query.toLowerCase())
      ) {
        return false;
      }
    }
    return true;
  };

  //update rows based on the store
  function updateRows() {
    rows = [];
    let currentSessionList = props.sessionList;

    if (currentSessionList !== undefined) {
      for (let i = 0; i < currentSessionList.length; i++) {
        const currentSession = currentSessionList[i];
        rows.push(
          createData(
            notUndefined(currentSession.id),
            notUndefined(currentSession.phone),
            notUndefined(currentSession.currentState),
            notUndefined(currentSession.active),
            notUndefined(apiToLocalTimezone(currentSession.updatedTimestamp, timezone)),
            notUndefined(currentSession.sessionType),
            notUndefined(parseInt(currentSession.campaign, 10))
          )
        );
      }
    }
  }

  function handleNumberOfSessionsChange(e) {
    setnumberOfSessions(e.target.value);
  }

  function callbackFilter() {
    setToggle(!toggle);
  }

  async function dispatchFetchRequest() {
    if (!validateTimezone(timezone)) { return; }
    setIsLoading(true);
    await props.dispatch(
      fetchSessionsByDates(
        toRequestTimestamp(dateFrom, timezone),
        toRequestTimestamp(dateTo, timezone),
        numberOfSessions
      )
    );
    setIsLoading(false);
  }
  return (
    <MuiThemeProvider theme={theme}>
      <CircularIndeterminate isLoading={isLoading} />
      <div>
        <Paper style={{ overflow: "auto" }}>
          <div align="center">
            <Grid container style={{ marginTop: "20px" }} spacing={24}>
              <Grid item xs={13}>
                <SessionTableToolbar />
              </Grid>
              <Grid item xs={13}>
                <Tooltip
                  title="Large number may take longer to query"
                  placement="top-start"
                >
                  <TextField
                    select
                    style={{
                      width: "120px",
                      margin: "10px",
                      marginRight: "30px"
                    }}
                    label="Number of Rows"
                    value={numberOfSessions}
                    onFocus={updateRows()}
                    onChange={handleNumberOfSessionsChange}
                  >
                    {[
                      {
                        value: "5",
                        label: "5"
                      },
                      {
                        value: "10",
                        label: "10"
                      },
                      {
                        value: "25",
                        label: "25"
                      },
                      {
                        value: "50",
                        label: "50"
                      },
                      {
                        value: "100",
                        label: "100"
                      },
                      {
                        value: "500",
                        label: "500"
                      }
                    ].map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Tooltip>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    style={{ padding: "10px", width: 180 }}
                    id="From"
                    label="From"
                    allowKeyboardControl={true}
                    ampm={false}
                    format="yyyy-MM-dd   HH:mm"
                    value={dateFrom}
                    onChange={handleFromDateChange}
                    showTodayButton
                  />
                  <DateTimePicker
                    style={{ padding: "10px", width: 180 }}
                    id="To"
                    label="To"
                    ampm={false}
                    format="yyyy-MM-dd   HH:mm"
                    value={dateTo}
                    onChange={handleToDateChange}
                    showTodayButton
                  />
                </MuiPickersUtilsProvider>
                <TextField
                  id="timezone"
                  label="Timezone (IANA)"
                  variant="filled"
                  helperText="Please use IANA format such as 'America/Toronto'"
                  defaultValue="America/Toronto"
                  value={timezone}
                  onChange={event => { setIsValidTimezone(validateTimezone(event.target.value)); setTimezone(event.target.value); }}
                  error={!isValidTimezone}
                />
                <Button
                  color="primary"
                  variant="contained"
                  style={{
                    padding: "5px",
                    background: munvoOrange,
                    marginLeft: "20px"
                  }}
                  onClick={dispatchFetchRequest}
                >
                  submit
                </Button>
              </Grid>
            </Grid>
          </div>
          <div>
            <Table aria-labelledby="tableTitle" width="100%">
              <SessionTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                callbackFilter={callbackFilter}
              />
              <TableBody>
                {stableSort(rows, getSorting(order, orderBy))
                  .filter(filterFunction)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => {
                    return (
                      <TableRow hover key={row.name}>
                        <TableCell align="right">
                          <span style={theme.tableCell80}>{row.sessionId}</span>
                        </TableCell>
                        <TableCell align="right">
                          <span style={theme.tableCell80}>
                            {row.phoneNumber}
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          <span style={theme.tableCell80}>{row.nodeId}</span>
                        </TableCell>
                        <TableCell align="right">
                          <span style={theme.tableCell80}>{row.active}</span>
                        </TableCell>
                        <TableCell align="right">
                          <span style={theme.tableCell80}>{row.sessionType}</span>
                        </TableCell>
                        <TableCell align="right">
                          <span style={theme.tableCell120}>{row.tupdated}</span>
                        </TableCell>
                        <TableCell align="left">
                          <span align="left" style={theme.tableCell80}>
                            {row.scampaignId}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </MuiThemeProvider>
  );
};
const mapStateToProps = state => {
  return {
    sessionList: state.tables.sessionList
  };
};
export default connect(mapStateToProps)(SessionTable);
