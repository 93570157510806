import * as Types from "./types";
import "cross-fetch/polyfill";
import { createAction } from "redux-actions";
import { getJwtToken } from "../../utils/generalUtils";
import { API_VERSION } from "../../utils/Constants";

const fetchSessionsError = createAction(Types.FETCH_SESSIONS_FAILURE);
const fetchSessionsAction = createAction(
  Types.FETCH_SESSIONS_SUCCESS,
  payload => payload
);

const fetchTransactionErrorsError = createAction(
  Types.FETCH_TRANSACTION_ERRORS_FAILURE
);
const fetchTransactionErrorsAction = createAction(
  Types.FETCH_TRANSACTION_ERRORS_SUCCESS,
  payload => payload
);

const fetchBlacklistsError = createAction(Types.FETCH_BLACKLISTS_FAILURE);
const fetchBlacklistsAction = createAction(
  Types.FETCH_BLACKLISTS_SUCCESS,
  payload => payload
);

const fetchWebhookErrorLogsError = createAction(
  Types.FETCH_WEB_HOOK_ERROR_LOG_FAILURE
);
const fetchWebhookErrorLogsAction = createAction(
  Types.FETCH_WEB_HOOK_ERROR_LOG_SUCCESS,
  payload => payload
);

const fetchWebhookLogsError = createAction(
  Types.FETCH_WEB_HOOK_LOG_FAILURE
);
const fetchWebhookLogsAction = createAction(
  Types.FETCH_WEB_HOOK_LOG_SUCCESS,
  payload => payload
);

const fetchTransactionsError = createAction(Types.FETCH_TRANSACTIONS_FAILURE);
const fetchTransactionsAction = createAction(
  Types.FETCH_TRANSACTIONS_SUCCESS,
  payload => payload
);

const fetchSessionTransactionsError = createAction(Types.FETCH_SESSION_TRANSACTIONS_FAILURE);
const fetchSessionTransactionsAction = createAction(
  Types.FETCH_SESSION_TRANSACTIONS_SUCCESS,
  payload => payload
);

export const fetchSessionsByDates = (
  fromDate,
  toDate,
  sessionNumber
) => async dispatch => {
  let response;
  sessionNumber = sessionNumber === 0 ? 10 : sessionNumber;

  response = await fetch(
    API_VERSION +
      "session/sessioncount/" +
      sessionNumber +
      "/fromdate/" +
      fromDate +
      "/todate/" +
      toDate,
    {
      method: "GET",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getJwtToken()
      }
    }
  ).catch(e => {
    dispatch(fetchSessionsError());
  });

  const json = await response.json();

  dispatch(fetchSessionsAction(json));
};

export const fetchTransactionErrorsByDates = (
  fromDate,
  toDate,
  transactionErrorNumber
) => async dispatch => {
  let response;
  transactionErrorNumber =
    transactionErrorNumber === 0 ? 10 : transactionErrorNumber;
  response = await fetch(
    API_VERSION +
      "transactionerror/transactionerrorcount/" +
      transactionErrorNumber +
      "/fromdate/" +
      fromDate +
      "/todate/" +
      toDate,
    {
      method: "GET",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getJwtToken()
      }
    }
  ).catch(e => {
    dispatch(fetchTransactionErrorsError());
  });

  const json = await response.json();

  dispatch(fetchTransactionErrorsAction(json));
};

export const fetchBlacklistsByDates = (
  fromDate,
  toDate,
  blacklistNumber
) => async dispatch => {
  let response;
  blacklistNumber = blacklistNumber === 0 ? 10 : blacklistNumber;
  response = await fetch(
    API_VERSION +
      "blacklist/blacklistcount/" +
      blacklistNumber +
      "/fromdate/" +
      fromDate +
      "/todate/" +
      toDate,
    {
      method: "GET",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getJwtToken()
      }
    }
  ).catch(e => {
    dispatch(fetchBlacklistsError());
  });

  const json = await response.json();

  dispatch(fetchBlacklistsAction(json));
};

export const fetchWebhookErrorLogsByDates = (
  fromDate,
  toDate,
  webhookErrorLogNumber
) => async dispatch => {
  let response;
  webhookErrorLogNumber = webhookErrorLogNumber === 0 ? 10 : webhookErrorLogNumber;
  response = await fetch(
    API_VERSION +
      "webhookurlerrorlog/logcount/" +
      webhookErrorLogNumber +
      "/startdate/" +
      fromDate +
      "/enddate/" +
      toDate,
    {
      method: "GET",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getJwtToken()
      }
    }
  ).catch(e => {
    dispatch(fetchWebhookErrorLogsError());
  });

  const json = await response.json();
  dispatch(fetchWebhookErrorLogsAction(json));
};

export const fetchWebhookLogsByDates = (
  fromDate,
  toDate,
  webhookLogNumber
) => async dispatch => {
  let response;
  webhookLogNumber = webhookLogNumber === 0 ? 10 : webhookLogNumber;
  response = await fetch(
    API_VERSION +
      "webhooklog/logcount/" +
      webhookLogNumber +
      "/startdate/" +
      fromDate +
      "/enddate/" +
      toDate,
    {
      method: "GET",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getJwtToken()
      }
    }
  ).catch(e => {
    dispatch(fetchWebhookLogsError());
  });

  const json = await response.json();

  dispatch(fetchWebhookLogsAction(json));
};

export const fetchTransactionsByDates = (
  fromDate,
  toDate,
  transactionNumber
) => async dispatch => {
  let response;
  transactionNumber = transactionNumber === 0 ? 10 : transactionNumber;
  response = await fetch(
    API_VERSION +
      "transactions/transactioncount/" +
      transactionNumber +
      "/fromdate/" +
      fromDate +
      "/todate/" +
      toDate,
    {
      method: "GET",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getJwtToken()
      }
    }
  ).catch(e => {
    dispatch(fetchTransactionsError);
  });

  const json = await response.json();

  dispatch(fetchTransactionsAction(json));
};

export const fetchSessionTransactions = (
  sessionIdOrPhoneNumber,
  sessionIdOrPhoneNumberType,
  campaignId,
  fromDate,
  toDate,
  numberOfSessionTransactions
  ) => async dispatch => {
    // campaignId and fromDate+toDate are each optional and so if are empty will
    // call the endpoint without it in the path, respectively
    let response = await fetch(
      API_VERSION +
        "transactions" +
        "/idnumber/" + sessionIdOrPhoneNumber +
        "/idtype/" + sessionIdOrPhoneNumberType +
        (campaignId ? "/campaignid/" + campaignId : "") +
        "/transactioncount/" + numberOfSessionTransactions +
        (fromDate && toDate ? "/fromdate/" + fromDate +
        "/todate/" + toDate : ""),
      {
        method: "GET",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getJwtToken()
        }
      }
    ).catch(e => {
      dispatch(fetchSessionTransactionsError);
    });
  
  const json = await response.json();
  
  dispatch(fetchSessionTransactionsAction(json));
};
