import React from "react";
import ReactTooltip from "react-tooltip";

export const LoginTooltip = () => {
  return (
    <ReactTooltip id='login-tt' place='bottom' type='info' effect='solid'>
      <span>Login to the SMS Connector app</span>
    </ReactTooltip>
  );
};

export const DelResponseTooltip = () => {
  return (
    <ReactTooltip id='delete-response-tt' place='top' type='info' effect='solid' className="__react_component_tooltip_custom">
      <span>Delete this SMS response node and all its children</span>
    </ReactTooltip>
  );
};

export const AddChildTooltip = () => {
  return (
    <ReactTooltip id='add-child-tt' place='top' type='info' effect='solid' className="__react_component_tooltip_custom">
      <span>Add a SMS Response as a child of this response</span>
    </ReactTooltip>
  );
};

export const EditTooltip = () => {
  return (
    <ReactTooltip id='edit-tt' place='top' type='info' effect='solid' className="__react_component_tooltip_custom">
      <span>Edit this SMS response</span>
    </ReactTooltip>
  );
};

export const PrevResultTooltip = () => {
  return (
    <ReactTooltip id='prev-result-tt' place='top' type='info' effect='solid'>
      <span>Target previous search result</span>
    </ReactTooltip>
  );
};

export const NextResultTooltip = () => {
  return (
    <ReactTooltip id='next-result-tt' place='top' type='info' effect='solid'>
      <span>Target next search result</span>
    </ReactTooltip>
  );
};

export const AddResponseTooltip = () => {
  return (
    <ReactTooltip id='add-response-tt' place='right' type='info' effect='solid'>
      <span>Create a new SMS response to add to the flowchart</span>
    </ReactTooltip>
  );
};

export const SaveFlowchartTooltip = () => {
  return (
    <ReactTooltip id='save-tt' place='bottom' type='info' effect='solid'>
      <span>Save and publish this flowchart</span>
    </ReactTooltip>
  );
};

export const DeleteFlowchartTooltip = () => {
  return (
    <ReactTooltip id='delete-tt' place='bottom' type='info' effect='solid'>
      <span>Delete this flowchart</span>
    </ReactTooltip>
  );
};

export const DuplicateFlowchartTooltip = () => {
  return (
    <ReactTooltip id='duplicate-tt' place='bottom' type='info' effect='solid'>
      <span>Duplicate this flowchart</span>
    </ReactTooltip>
  );
};

export const ExpandFlowchartTooltip = () => {
  return (
    <ReactTooltip id='expand-tt' place='bottom' type='info' effect='solid'>
      <span>Expands the flowchart (reveals all children nodes)</span>
    </ReactTooltip>
  );
};

export const CollapseFlowchartTooltip = () => {
  return (
    <ReactTooltip id='collapse-tt' place='bottom' type='info' effect='solid'>
      <span>Collapses the flowchart (hides all children nodes)</span>
    </ReactTooltip>
  );
};

export const SearchNodesTooltip = () => {
  return (
    <ReactTooltip id='search-tt' place='bottom' type='info' effect='solid'>
      <span>
        Search through nodes in the flowchart.
        <br />
        Entering a phone number will search for a customer's current path in the
        tree. Other text will find a node by customer response and reply text.
      </span>
    </ReactTooltip>
  );
};

export const FindFlowchartTooltip = () => {
  return (
    <ReactTooltip id='search-flowchart-tt' place='bottom' type='info' effect='solid'>
      <span>Find a flowchart by name or long/short code</span>
    </ReactTooltip>
  );
};

export const FindTemplateTooltip = () => {
  return (
    <ReactTooltip id='search-template-tt' place='bottom' type='info' effect='solid'>
      <span>Find a template by name</span>
    </ReactTooltip>
  );
};

export const CopyAuthTokenToClipboardTooltip = () => {
  return (
    <ReactTooltip id='auth-token-clipboard-tt' place='right' type='info' effect='solid'>
      <span>Copy Auth Token to Clipboard</span>
    </ReactTooltip>
  );
};

export const AddUrlToWebhookListTooltip = () => {
  return (
    <ReactTooltip id='add-url-to-webhook-list-tt' place='right' type='info' effect='solid'>
      <span>Add Webhook URL to webhook list</span>
    </ReactTooltip>
  );
};

export const TestWebhookUrlTooltip = () => {
  return (
    <ReactTooltip id='test-webhook-url-tt' place='left' type='info' effect='solid'>
      <span>Test selected Webhook URL</span>
    </ReactTooltip>
  );
};

export const RemoveWebhookURLTooltip = () => {
  return (
    <ReactTooltip id='remove-webhook-url-tt' place='right' type='info' effect='solid'>
      <span>Remove Webhook URL from list</span>
    </ReactTooltip>
  );
};

export const CourierTestCredentialsTooltip = () => {
  return (
    <ReactTooltip id='courier-test-credential-tt' place='right' type='info' effect='solid'>
      <span>Test Courier Credential</span>
    </ReactTooltip>
  );
};

export const AddUserTooltip = () => {
  return (
    <ReactTooltip id='add-user-tt' place='right' type='info' effect='solid'>
      <span>To create a new user</span>
    </ReactTooltip>
  )
}

export const ResetSessionTooltip = () => {
  return (
    <ReactTooltip id='reset-session-tt' place='left' type='info' effect='solid' event='click'>
      <span>Country code is required - e.g. For 1-613-555-0183 please input 16135550183</span>
    </ReactTooltip>
  )
}

export const EngagementScoreTooltip = () => {
  return (
    <ReactTooltip id='engagement-score-tt' place='right' type='success' effect='solid'>
      <span>Average number of nodes visited per phone number</span>
    </ReactTooltip>
  );
};