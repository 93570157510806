import * as Types from './types'

const initialState = () => {
  if(localStorage.getItem('jwt-token')) {
    return {
      loggedIn: 1,
      username: localStorage.getItem('username'),
      role: localStorage.getItem('role'),
      invalidate: false,
      loading: false,
      rememberMe: true,
      timeout: localStorage.getItem('timeout')
    }
  }else if(sessionStorage.getItem('jwt-token')) {
    return {
      loggedIn: 1,
      username: sessionStorage.getItem('username'),
      role: sessionStorage.getItem('role'),
      invalidate: false,
      loading: false,
      rememberMe: false,
      timeout: sessionStorage.getItem('timeout')
    }
  }else {
    return {
      loggedIn: 0,
      username: '',
      role: '',
      invalidate: false,
      loading: false,
      rememberMe: false,
      timeout: 0
    }
  }
}

export const authenticationReducer = (state = initialState(), action) => {
  switch(action.type){
    case Types.LOGIN_REQUEST:
      return {
        loggedIn: 0,
        loading: true
      }
    case Types.LOGIN_SUCCESS:
      return {
        loggedIn: 1,
        username: action.username,
        role: action.role,
        rememberMe: action.rememberMe,
        loading: false,
        timeout: action.timeout
      }
    case Types.LOGIN_FAILURE:
      return {
        loggedIn: 0,
        username: '',
        invalidate: true,
        loading: false,
        rememberMe: false
      }
    case Types.LOGOUT:
    case Types.LOGOUT_FAILURE:
      return {
        loggedIn: 0,
        username: '',
        invalidate: false,
        loading: false,
        rememberMe: false
      }
    default:
      return state
  }
}