import React, { Component } from "react";
import { AppBar, IconButton } from "material-ui";
import classnames from "classnames";
import { logout } from "../reducers";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import { Link } from "react-router-dom";
import { abilities } from "../utils/Ability";
import { Can } from "@casl/react";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Dialog } from "material-ui";
import { munvoBlue } from "../utils/globalStyles";
import { headerStyles, theme } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { flowchartActions } from "../reducers";

class HeaderBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      ability: abilities[this.props.role],
      openUnsaved: false,
      route: null
    };

    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleOpenUnsaved = this.handleOpenUnsaved.bind(this);
    this.handleCloseUnsaved = this.handleCloseUnsaved.bind(this);
  }

  handleOpenUnsaved(route) {
    this.setState({
      openUnsaved: true,
      route: route
    });
  }

  handleCloseUnsaved() {
    this.setState({
      openUnsaved: false,
      route: null
    });
  }

  handleSignOut(e) {
    const { username } = this.props;
    this.props.dispatch(logout(username));
  }

  handleMenuClick(e) {
    this.setState({ anchorEl: e.currentTarget });
  }

  handleMenuClose(e) {
    this.setState({ anchorEl: null });
  }

  render() {
    const { anchorEl } = this.state;
    const { username, pageTitle } = this.props;
    const menuItems = (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={anchorEl ? "long-menu" : null}
          aria-haspopup="true"
          onClick={this.handleMenuClick}
          iconStyle={{ color: "white" }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleMenuClose}
          PaperProps={{
            style: {
              width: 200
            }
          }}
        >
          <Link to="/profile" style={{ textDecoration: "none", color: "black" }}>
            <MenuItem style={{ borderBottom: "1px solid" }}>{username}</MenuItem>
          </Link>

          <Link to="/menu" style={{ textDecoration: "none", color: "black" }}>
            <MenuItem style={{ fontWeight: "bold" }}>Flowcharts</MenuItem>
          </Link>

          <Link to="/tables" style={{ textDecoration: "none", color: "black" }}>
            <MenuItem style={{ fontWeight: "bold" }}>Tables</MenuItem>
          </Link>

          <Link to='/reports' style={{ textDecoration: 'none', color: "black" }}>
            <MenuItem style={{ fontWeight: 'bold' }}>Reporting Dashboard</MenuItem>
          </Link>

          <Link to='/template' style={{ textDecoration: 'none', color: "black" }}>
            <MenuItem style={{ fontWeight: 'bold' }}>Templates</MenuItem>
          </Link>

          <Link to='/nodetemplates' style={{ textDecoration: 'none', color: "black" }}>
            <MenuItem style={{ fontWeight: 'bold' }}>Node Templates</MenuItem>
          </Link>

          <Link to='/monitoring' style={{ textDecoration: 'none', color: "black" }}>
            <MenuItem style={{ fontWeight: 'bold' }}>Monitoring Dashboard</MenuItem>
          </Link>

          <Can I="access" a="settings" ability={this.state.ability}>
            <Link to="/users" style={{ textDecoration: "none", color: "black" }}>
              <MenuItem style={{ fontWeight: "bold" }}>Users</MenuItem>
            </Link>

            <Link to="/settings" style={{ textDecoration: "none", color: "black" }}>
              <MenuItem style={{ fontWeight: "bold" }}>Settings</MenuItem>
            </Link>

          </Can>


          <MenuItem style={{ fontWeight: 'bold' }} onClick={this.handleSignOut}>Sign Out</MenuItem>
        </Menu>
      </div>
    );

    const menuItemsUnsaved = (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={anchorEl ? "long-menu" : null}
          aria-haspopup="true"
          onClick={this.handleMenuClick}
          iconStyle={{ color: "white" }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleMenuClose}
          PaperProps={{
            style: {
              width: 200
            }
          }}
        >
          <MenuItem style={{ borderBottom: "1px solid" }} onClick={() => this.handleOpenUnsaved("/profile")}>{username}</MenuItem>

          <MenuItem style={{ fontWeight: "bold" }} onClick={() => this.handleOpenUnsaved("/")}>Flowcharts</MenuItem>

          <MenuItem
            onClick={() => this.handleOpenUnsaved("/tables")}
            style={{ fontWeight: "bold" }}
          >
            Tables
          </MenuItem>

          <MenuItem
            onClick={() => this.handleOpenUnsaved("/reports")}
            style={{ fontWeight: "bold" }}
          >
            Reporting Dashboard
          </MenuItem>

          <MenuItem
            style={{ fontWeight: "bold" }}
            onClick={() => this.handleOpenUnsaved("/template")}
          >
            Templates
          </MenuItem>

          <MenuItem
            style={{ fontWeight: "bold" }}
            onClick={() => this.handleOpenUnsaved("/nodetemplates")}
          >
            Node Templates
          </MenuItem>


          <MenuItem
            style={{ fontWeight: 'bold' }}
            onClick={() => this.handleOpenUnsaved("/monitoring")}
          >Monitoring Dashboard
          </MenuItem>


          <Can I="access" a="settings" ability={this.state.ability}>
            <MenuItem
              style={{ fontWeight: "bold" }}
              onClick={() => this.handleOpenUnsaved("/users")}
            >
              Users
            </MenuItem>

            <MenuItem
              style={{ fontWeight: "bold" }}
              onClick={() => this.handleOpenUnsaved("/settings")}
            >
              Settings
            </MenuItem>

          </Can>

          <MenuItem style={{ fontWeight: "bold" }} onClick={this.handleSignOut}>
            Sign Out
          </MenuItem>

        </Menu>
      </div>
    );

    const RouteButton = withRouter(props => (
      <Button
        color="primary"
        variant="outlined"
        className={this.props.classes.button}
        onClick={() => {
          props.history.push(props.route);
          this.props.dispatch(flowchartActions.saveFlowchart());
        }}
      >
        Continue Without Saving
      </Button>
    ));

    const UnsavedFlowchartDialog = () => {
      const { classes } = this.props;
      return (
        <Grid item xs={12}>
          <Dialog
            title="You have an unsaved flowchart"
            actions={[
              <Button
                variant="outlined"
                onClick={this.handleCloseUnsaved}
                className={classes.button}
              >
                Cancel
              </Button>,
              <MuiThemeProvider theme={theme}>
                <RouteButton route={this.state.route} />
              </MuiThemeProvider>
            ]}
            open={this.state.openUnsaved}
            onRequestClose={this.handleCloseUnsaved}
            autoScrollBodyContent={true}
            contentStyle={{
              position: "absolute",
              left: "50%",
              top: "50%",
              height: "80%",
              transform: "translate(-50%, -50%)"
            }}
          >
            Your current flowchart is unsaved. If you leave this page before
            saving you will lose any unsaved progress.
          </Dialog>
        </Grid>
      );
    };

    return (
      <div className="App">
        <AppBar
          className={classnames("app-bar", { expanded: true })}
          style={{ background: munvoBlue }}
          title={pageTitle}
          showMenuIconButton={false}
          iconElementRight={
            this.props.saved || this.props.tree.length < 1
              ? menuItems
              : menuItemsUnsaved
          }
        />
        <UnsavedFlowchartDialog />
      </div>
    );
  }
}

HeaderBar.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  username: state.authentication.username,
  role: state.authentication.role,
  saved: state.flowcharts.saved,
  tree: state.flowcharts.tree
});

export default connect(mapStateToProps)(withStyles(headerStyles)(HeaderBar));
