export const FETCH_SESSIONS_FAILURE = "FETCH_SESSIONS_FAILURE";
export const FETCH_SESSIONS_SUCCESS = "FETCH_SESSIONS_SUCCESS";

export const FETCH_TRANSACTION_ERRORS_FAILURE =
  "FETCH_TRANSACTION_ERRORS_FAILURE";
export const FETCH_TRANSACTION_ERRORS_SUCCESS =
  "FETCH_TRANSACTION_ERRORS_SUCCESS";

export const FETCH_BLACKLISTS_FAILURE = "FETCH_BLACKLISTS_FAILURE";
export const FETCH_BLACKLISTS_SUCCESS = "FETCH_BLACKLISTS_SUCCESS";

export const FETCH_WEB_HOOK_ERROR_LOG_FAILURE =
  "FETCH_WEB_HOOK_ERROR_LOG_FAILURE";
export const FETCH_WEB_HOOK_ERROR_LOG_SUCCESS =
  "FETCH_WEB_HOOK_ERROR_LOG_SUCCESS";

export const FETCH_WEB_HOOK_LOG_FAILURE =
  "FETCH_WEB_HOOK_LOG_FAILURE";
export const FETCH_WEB_HOOK_LOG_SUCCESS =
  "FETCH_WEB_HOOK_LOG_SUCCESS";

export const FETCH_TRANSACTIONS_FAILURE = "FETCH_TRANSACTIONS_FAILURE";
export const FETCH_TRANSACTIONS_SUCCESS = "FETCH_TRANSACTIONS_SUCCESS";

export const FETCH_SESSION_TRANSACTIONS_FAILURE =
  "FETCH_SESSION_TRANSACTIONS_FAILURE";
export const FETCH_SESSION_TRANSACTIONS_SUCCESS =
  "FETCH_SESSION_TRANSACTIONS_SUCCESS";
