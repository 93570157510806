import * as Types from "./types";
import "cross-fetch/polyfill";
import { createAction } from "redux-actions";
import { getJwtToken } from "../../utils/generalUtils";
import * as Cookies from "js-cookie";
import { API_VERSION } from "../../utils/Constants";

const configurationRequest = createAction(Types.CONFIGURATION_REQUEST);
const configurationSuccess = createAction(
  Types.CONFIGURATION_SUCCESS,
  payload => payload
);
const configurationFailure = createAction(Types.CONFIGURATION_FAILURE);
const forceLogout = createAction(Types.LOGOUT);

export const getConfiguration = () => async dispatch => {
  dispatch(configurationRequest());
  const response = await fetch(API_VERSION + "configuration", {
    method: "GET",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    }
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(configurationFailure());
  } else {
    const body = await response.json();
    dispatch(configurationSuccess(body.configList));
  }
};

export const putConfiguration = updateConfiguration => async dispatch => {
  dispatch(configurationRequest());
  const response = await fetch(API_VERSION + "configuration", {
    method: "PUT",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    },
    body: JSON.stringify(updateConfiguration)
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(configurationFailure());
  } else {
    const body = await response.json();
    dispatch(configurationSuccess(body.configList));
  }
};
