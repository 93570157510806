import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import App from "./App";
import "./index.scss";
import "react-confirm-alert/src/react-confirm-alert.css";
import "swagger-ui-react/swagger-ui.css";

/*
 ________  ___ _____   _____       _                           
/  ___|  \/  |/  ___| |  __ \     | |                          
\ `--.| .  . |\ `--.  | |  \/ __ _| |_ _____      ____ _ _   _ 
 `--. \ |\/| | `--. \ | | __ / _` | __/ _ \ \ /\ / / _` | | | |
/\__/ / |  | |/\__/ / | |_\ \ (_| | ||  __/\ V  V / (_| | |_| |
\____/\_|  |_/\____/   \____/\__,_|\__\___| \_/\_/ \__,_|\__, |
                                                          __/ |
                                                         |___/                                                                                           
*/

ReactDOM.render(<App />, document.getElementById("root"));
