export const API_VERSION = "api/v1/";


export const AT_LEAST_ONE_LETTER = /(.*[a-z].*)/;
export const AT_LEAST_ONE_CAPITAL = /(.*[A-Z].*)/;
export const AT_LEAST_ONE_NUMBER = /(.*[0-9].*)/;



export const ALPHANUMERIC_PATTERN = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/; //only letters and number(ie no symboles) 
export const PASSWORDLENGTH = 8;

export const COURIER_ARRET = "ARRET";
export const COURIER_OPT_OUT = "OPT-OUT";
export const COURIER_OPTOUT = "OPTOUT";
export const COURIER_REMOVE = "REMOVE";
export const COURIER_TD = "TD";
export const COURIER_STOP = "STOP";
export const COURIER_START = "START";
export const COURIER_HELP = "HELP";
export const COURIER_UNSUBSCRIBE = "UNSUBSCRIBE";
export const COURIER_CANCEL = "CANCEL";
export const COURIER_QUIT = "QUIT";
export const COURIER_YES = "YES";
export const COURIER_UNSTOP = "UNSTOP";
export const COURIER_INFO = "INFO";
export const COURIER_END = "END";
export const COURIER_STOPALL = "STOPALL";
export const PHONE_PATTERN = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
export const DELIMITER = "++";

export const DEFAULT_REPORTING_LAST_X_DAYS = 30;
export const REPORTING_LAST_X_DAYS_CONFIG_KEY = "reporting|last|x|days";
