
import React from 'react'
import Menu from './Menu'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import { Provider } from 'react-redux'
import createHistory from 'history/createHashHistory'
import { ConnectedRouter } from 'react-router-redux'
import { Switch, Route } from 'react-router-dom'
import Login from './loginPage'
import PrivateRoute from './PrivateRoute'
import Home from './home'
import Settings from './Settings'
import Users from './userPage'
import { store } from './reducers/store'
import NodeTemplate from './NodeTemplate'
import TemplateMenu from './TemplateMenu'
import Profile from './Profile'
import Reports from './Reports'
import Tables from './Tables'
import Swagger from "./Swagger";
import Monitoring from './Monitoring'


const history = createHistory();

const App = () => {
  return (
    <MuiThemeProvider>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/login' component={Login} />
            <PrivateRoute path="/swagger" component={Swagger} />
            <PrivateRoute path='/menu' component={Menu} />
            <PrivateRoute path='/settings' component={Settings} />
            <PrivateRoute path='/users' component={Users} />
            <PrivateRoute path='/tables' component={Tables} />
            <PrivateRoute path='/template' component={TemplateMenu} />
            <PrivateRoute path='/nodetemplates' component={NodeTemplate} />
            <PrivateRoute path='/profile' component={Profile} />
            <PrivateRoute path='/reports' component={Reports} />
            <PrivateRoute path='/monitoring' component={Monitoring} />

          </Switch>
        </ConnectedRouter>
      </Provider>
    </MuiThemeProvider>
  );
};

export default App;
