import * as Types from "./types";
import "cross-fetch/polyfill";
import { createAction } from "redux-actions";
import { getJwtToken } from "../../utils/generalUtils";
import { API_VERSION } from "../../utils/Constants";

import * as Cookies from "js-cookie";

const nodeTemplateRequest = createAction(Types.NODE_TEMPLATE_REQUEST);
const nodeTemplateSuccess = createAction(
  Types.NODE_TEMPLATE_SUCCESS,
  payload => payload
);
const nodeTemplateFailure = createAction(Types.NODE_TEMPLATE_FAILURE);
const forceLogout = createAction(Types.LOGOUT);

export const getNodeTemplates = () => async dispatch => {
  dispatch(nodeTemplateRequest());
  const response = await fetch(API_VERSION + "nodetemplate", {
    method: "GET",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    }
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(nodeTemplateFailure());
  } else {
    const body = await response.json();
    dispatch(nodeTemplateSuccess(body.nodeTemplateList));
  }
};

export const postNodeTemplate = newNodeTemplate => async dispatch => {
  dispatch(nodeTemplateRequest());
  const response = await fetch(API_VERSION + "nodetemplate", {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    },
    body: JSON.stringify(newNodeTemplate)
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(nodeTemplateFailure());
  } else {
    const body = await response.json();
    dispatch(nodeTemplateSuccess(body.nodeTemplateList));
  }
};

export const putNodeTemplate = editNodeTemplate => async dispatch => {
  dispatch(nodeTemplateRequest());
  const response = await fetch(API_VERSION + "nodetemplate", {
    method: "PUT",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    },
    body: JSON.stringify(editNodeTemplate)
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(nodeTemplateFailure());
  } else {
    const body = await response.json();
    dispatch(nodeTemplateSuccess(body.nodeTemplateList));
  }
};

export const deleteNodeTemplate = queryParams => async dispatch => {
  dispatch(nodeTemplateRequest());
  const response = await fetch(
    API_VERSION + "nodetemplate?".concat(queryParams),
    {
      method: "DELETE",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + getJwtToken()
      }
    }
  );

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(nodeTemplateFailure());
  } else {
    const body = await response.json();
    dispatch(nodeTemplateSuccess(body.nodeTemplateList));
  }
};

const nodeTemplateActions = {
  getNodeTemplates,
  postNodeTemplate
};

export { nodeTemplateActions };
