import React from 'react'
import { connect } from 'react-redux'
import { submit } from 'redux-form'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import { theme } from './styles'

// const RemoteSubmitButton = ({ component,form,dispatch,...props }) =>{
//   const Component=component
//   return (
//     <Component
//       onClick={() => dispatch(submit(form))}
//       {...props}
//     />
//   )
// }

const RemoteSubmitButton = ({ component, form, dispatch, label, ...props }) => {
  const Component = component

  return(
    <MuiThemeProvider theme={theme}>
      <Component
        onClick={() => dispatch(submit(form))}
        {...props}
      >
        {label}
      </Component>
    </MuiThemeProvider>
  )
}


export default connect()(RemoteSubmitButton)
