import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {
  fetchTwilioCredentials,
  fetchAwsCredentials,
  fetchSinchCredentials,
  fetchTelusCredentials,
  getConfiguration
} from "../reducers";
import { connect } from "react-redux";
import DoneIcon from "material-ui/svg-icons/action/done";
import ErrorIcon from "material-ui/svg-icons/alert/error";
import { CourierTestCredentialsTooltip } from "../utils/Tooltip";

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

class CourierCredentials extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(getConfiguration());

    this.state = { valid: null };
  }

  async componentDidMount() {
    this.props.dispatch(getConfiguration());
    //wait until the config get setup
    await sleep(500);

    let check =
      this.props.data.find(x => x.key === "courier|provider") === undefined
        ? ""
        : this.props.data.find(x => x.key === "courier|provider").value;
    switch (check) {
      case "twilio":
        this.props.dispatch(fetchTwilioCredentials());
        break;
      case "aws":
        this.props.dispatch(fetchAwsCredentials());
        break;
      case "sinch":
        this.props.dispatch(fetchSinchCredentials());
        break;
      case "telus":
        this.props.dispatch(fetchTelusCredentials());
        break;
      default:
        this.props.dispatch(fetchTwilioCredentials());
    }
    this.props.dispatch(getConfiguration());
  }

  render() {
    const { twilioValid, sinchValid, awsValid, telusValid, adblockDetected } = this.props;

    let ValidMessage;
    if (adblockDetected) {
      ValidMessage = (
        <span>
          {" "}
          &nbsp;
          <ErrorIcon />
          Unable to validate Courier Credentials. Please disable any ad blocker.
        </span>
      );
    } else if (twilioValid || sinchValid || awsValid || telusValid) {
      ValidMessage = (
        <span>
          {" "}
          &nbsp;
          <DoneIcon />
          Valid Credentials
        </span>
      );
    } else if (
      (twilioValid === false || twilioValid === undefined) &&
      (sinchValid === false || sinchValid === undefined) &&
      (awsValid === false || awsValid === undefined) &&
      (telusValid === false || telusValid === undefined)
    ) {
      ValidMessage = (
        <span>
          {" "}
          &nbsp;
          <ErrorIcon />
          Invalid username/password combination. Please insert proper
          credentials in the configuration page.
        </span>
      );
    }

    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            Courier Credentials
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline={true}
              rows={1}
              value={
                this.props.twilioUsername ||
                this.props.awsUsername ||
                this.props.sinchUsername ||
                this.props.telusUsername
              }
              disabled={true}
              fullWidth={true}
              margin="normal"
              style={{ width: "50%" }}
            />
          </Grid>
          <Grid item xs={12}>
            <CourierTestCredentialsTooltip />
            {ValidMessage}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    twilioUsername: state.twilioCredentials.username,
    twilioPassword: state.twilioCredentials.password,
    twilioCredentialsIsLoading: state.twilioCredentials.loading,
    awsUsername: state.awsCredentials.username,
    awsValid: state.awsCredentials.valid,
    sinchUsername: state.sinchCredentials.username,
    sinchValid: state.sinchCredentials.valid,
    telusUsername: state.telusCredentials.username,
    telusValid: state.telusCredentials.valid,
    twilioValid: state.twilioCredentials.valid,
    adblockDetected: state.urlList.adblockDetected,
    data: state.configuration.configurations
  };
};

export default connect(mapStateToProps)(CourierCredentials);
