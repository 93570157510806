import { AbilityBuilder } from 'casl'

/*
* Define user permissions in this file
*/

const admin = AbilityBuilder.define( can => {
    can('update', 'webhook')
    can('access', 'settings')
  })

const user = AbilityBuilder.define( can => {
    can('read', 'all')
  })  

export const abilities = { admin, user }