import * as Types from "./types";

export const urlListReducer = (state = { urlList: [] }, action) => {
  switch (action.type) {
    case Types.FETCH_URLS_REQUEST:
      return { ...state, loading: false };
    case Types.FETCH_URLS_SUCCESS:
      return { ...state, urlList: action.payload, loading: false };
    case Types.NEW_PING_SUCCESS:
      return { ...state, newUrlStatus: action.payload };
    case Types.TEST_PING_SUCCESS:
      return { ...state, testUrlStatus: "Responded!", adblockDetected: false };
    case Types.TEST_PING_FAILURE:
      return { ...state, testUrlStatus: "Failed" };
    case Types.NEW_PING_NOT_FOUND:
      return { ...state, newUrlStatus: action.payload };
    case Types.ADBLOCK_DETECTED:
      return { ...state, adblockDetected: true };
    default:
      return state;
  }
};

export const twilioCredentialsReducer = (state = {}, action) => {
  switch (action.type) {
    case Types.FETCH_TWILIO_CREDENTIALS_REQUEST:
      return { ...state, loading: true };
    case Types.FETCH_TWILIO_CREDENTIALS_SUCCESS:
      return {
        ...state,
        username: action.payload.twilio_username,
        password: action.payload.twilio_token
      };
    case Types.TEST_TWILIO_CREDENTIALS_SUCCESS:
      return { valid: true };
    case Types.TEST_TWILIO_CREDENTIALS_FAILURE:
      return { valid: false };
    default:
      return state;
  }
};

export const awsCredentialsReducer = (state = {}, action) => {
  switch (action.type) {
    case Types.FETCH_AWS_CREDENTIALS_REQUEST:
      return { ...state, loading: true };
    case Types.FETCH_AWS_CREDENTIALS_SUCCESS:
      return {
        ...state,
        username: action.payload.aws_username,
        loading: false
      };
    case Types.TEST_AWS_CREDENTIALS_SUCCESS:
      return { valid: true, loading: false };
    case Types.TEST_AWS_CREDENTIALS_FAILURE:
      return { valid: false, loading: false };
    default:
      return state;
  }
};

export const sinchCredentialsReducer = (state = {}, action) => {
  switch (action.type) {
    case Types.FETCH_SINCH_CREDENTIALS_REQUEST:
      return { ...state, loading: true };
    case Types.FETCH_SINCH_CREDENTIALS_SUCCESS:
      return {
        ...state,
        username: action.payload.sinch_username,
        loading: false
      };
    case Types.TEST_SINCH_CREDENTIALS_SUCCESS:
      return { valid: true, loading: false };
    case Types.TEST_SINCH_CREDENTIALS_FAILURE:
      return { valid: false, loading: false };
    default:
      return state;
  }
};

export const telusCredentialsReducer = (state = {}, action) => {
  switch (action.type) {
    case Types.FETCH_TELUS_CREDENTIALS_REQUEST:
      return { ...state, loading: true };
    case Types.FETCH_TELUS_CREDENTIALS_SUCCESS:
      return {
        ...state,
        username: action.payload.telus_clientid,
        loading: false
      };
    case Types.TEST_TELUS_CREDENTIALS_SUCCESS:
      return { valid: true, loading: false };
    case Types.TEST_TELUS_CREDENTIALS_FAILURE:
      return { valid: false, loading: false };
    default:
      return state;
  }
};
