import { createAction } from "redux-actions";
import {
  toggleExpandedForAll,
  removeNodeAtPath,
  addNodeUnderParent,
  changeNodeAtPath,
  insertNode
} from "react-sortable-tree";
import { parseTree, parseNode } from "../../utils/generalUtils";
import { getJwtToken, mapOnTree } from "../../utils/generalUtils";
import * as Types from "./types";
import * as Cookies from "js-cookie";
import { API_VERSION } from "../../utils/Constants";


const forceLogout = createAction(Types.LOGOUT);

const modifyFlowchart = createAction(Types.MODIFY_FLOWCHART);
const saveFlowchart = createAction(Types.SAVE_FLOWCHART);

const expandAll = createAction(Types.UPDATE_TREE, treeData => {
  return toggleExpandedForAll({ treeData, expanded: true });
});
const collapseAll = createAction(Types.UPDATE_TREE, treeData => {
  return toggleExpandedForAll({ treeData, expanded: false });
});
const updateSearchString = createAction(Types.SET_SEARCH_STRING);
const nextSearchResult = createAction(
  Types.SEARCH_INCREMENT,
  (searchFocusIndex, searchFoundCount) => {
    return searchFocusIndex !== null
      ? (searchFocusIndex + 1) % searchFoundCount
      : 0;
  }
);
const prevSearchResult = createAction(
  Types.SEARCH_DECREMENT,
  (searchFocusIndex, searchFoundCount) => {
    return searchFocusIndex !== null
      ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
      : searchFoundCount - 1;
  }
);
const updateTreeData = createAction(Types.UPDATE_TREE);

const updateSearchProps = createAction(
  Types.SEARCH_CALLBACK,
  (matches, searchFocusIndex) => {
    return {
      searchFoundCount: matches.length,
      searchFocusIndex:
        matches.length > 0 ? searchFocusIndex % matches.length : 0
    };
  }
);
const addNode = createAction(Types.UPDATE_TREE, (form, rowInfo, treeData) => {
  const getNodeKey = ({ treeIndex }) => treeIndex;
  const newNode = parseNode(form, rowInfo.path.length);

  return addNodeUnderParent({
    treeData,
    parentKey: rowInfo.path[rowInfo.path.length - 1],
    expandParent: true,
    getNodeKey,
    newNode
  }).treeData;
});

const insertLastNode = createAction(Types.UPDATE_TREE, (form, treeData) => {
  const getNodeKey = ({ treeIndex }) => treeIndex;
  const newNode = parseNode(form);

  return insertNode({
    treeData,
    depth: 0,
    minimumTreeIndex: treeData.length,
    newNode,
    getNodeKey,
    expandParent: true
  }).treeData;
});

const updateNode = createAction(
  Types.UPDATE_TREE,
  (form, rowInfo, treeData) => {
    const getNodeKey = ({ node: TreeNode, treeIndex: number }) => number;
    const newNode = parseNode(form, rowInfo.path.length - 1);
    return changeNodeAtPath({
      treeData,
      path: rowInfo.path,
      newNode,
      getNodeKey,
      ignoreCollapsed: true
    });
  }
);

const deleteNode = createAction(Types.UPDATE_TREE, (rowInfo, treeData) => {
  const getNodeKey = ({ node: TreeNode, treeIndex: number }) => number;
  //function to apply to all node in tree to check if they target the node being deleted
  var deletePortal = (node) => {
    if (node && node.targetNode && node.targetNode.name === rowInfo.node.name) {
      //if it is, change the target to a null target (removing the portal)
      node.targetNode = JSON.parse("{\"name\":\"\",\"id\":-1}");

    }
  }
  mapOnTree(treeData, false, deletePortal);

  return removeNodeAtPath({
    treeData,
    path: rowInfo.path,
    getNodeKey,
    ignoreCollapsed: true
  });
});

const loadFlowchart = createAction(Types.LOAD_TREE, tree => parseTree(tree));

const newFlowchart = createAction(Types.NEW_FLOWCHART, metadata => {
  const tree = parseTree({
    root: {},
    ...metadata
  });
  return tree;
});


const sessionResetIdle = createAction(Types.SESSION_RESET_IDLE);
const sessionResetFailure = createAction(Types.SESSION_RESET_FAILURE);
const sessionResetSuccess = createAction(Types.SESSION_RESET_SUCCESS);

export const resetIdle = () => async dispatch => {
  dispatch(sessionResetIdle());
};

export const resetSession = (sessionNumber, campaignId) => async dispatch => {
  await fetch(
    API_VERSION + "campaign/clearSession/" + sessionNumber + "/" + campaignId,
    {
      method: "PUT",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getJwtToken()
      }
    }
  )
    .then(response => response.text())
    .then(responseJSON => {
      const success = JSON.parse(responseJSON).success;
      if (success === false) {
        dispatch(sessionResetFailure());
      } else {
        dispatch(sessionResetSuccess());
      }
    });
};

const sessionPathRequest = createAction(
  Types.SESSION_PATH_REQUEST,
  payload => payload
);
const sessionPathFailure = createAction(
  Types.SESSION_PATH_FAILURE,
  payload => payload
);
const sessionPathSuccess = createAction(
  Types.SESSION_PATH_SUCCESS,
  payload => payload
);

export const getSessionPath = (sessionNumber, campaignId) => async dispatch => {
  dispatch(sessionPathRequest(sessionNumber));
  const phoneNumber = sessionNumber.replace(/\D/g, "");
  const response = await fetch(
    API_VERSION + "campaign/sessionPath/" + phoneNumber + "/" + campaignId,
    {
      method: "GET",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getJwtToken()
      }
    }
  );

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(
      sessionPathFailure("No session path found for this phone number.")
    );
  } else {
    const body = await response.json();
    if (body.sessionPath) {
      dispatch(sessionPathSuccess(body.sessionPath));
    } else {
      dispatch(sessionPathFailure(body.pathError));
    }
  }
};

const actions = {
  expandAll,
  collapseAll,
  updateSearchString,
  nextSearchResult,
  prevSearchResult,
  updateTreeData,
  updateSearchProps,
  loadFlowchart,
  deleteNode,
  addNode,
  updateNode,
  insertLastNode,
  newFlowchart,
  saveFlowchart,
  modifyFlowchart,
  sessionResetIdle,
  sessionResetFailure,
  sessionResetSuccess,
  getSessionPath
};

//Selectors
const getParsedFlowchart = state => state.tree;
const getSearchIndex = state => state.searchFocusIndex;
const getSearchCount = state => state.searchFoundCount;
const getSearchString = state => state.searchString;
const getMetadata = state => state.metadata;
const getRoot = state => state.root;

const selectors = {
  getParsedFlowchart,
  getSearchCount,
  getSearchIndex,
  getSearchString,
  getMetadata,
  getRoot
};

export { actions, selectors };
