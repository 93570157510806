import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { loginFormStyles } from "./styles";
import RemoteSubmitButton from "../RemoteSubmitButton";
import { connect } from "react-redux";
import { login } from "../reducers";
import LoginForm from "./LoginForm";
import CardActions from "@material-ui/core/CardActions";
import Checkbox from "@material-ui/core/Checkbox";
import logo from "../Logo_SMSGateway.png";
import Button from "@material-ui/core/Button";

class LoginCard extends Component {
  constructor(props) {
    super(props);

    this.state = { rememberMe: false };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRememberMe = this.handleRememberMe.bind(this);
  }

  handleSubmit(form) {
    this.props.dispatch(login(form, this.state.rememberMe));
  }

  handleRememberMe(e) {
    this.setState({ rememberMe: e.target.checked });
  }

  render() {
    const { classes, invalidate } = this.props;

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.textCenter}>
              <img src={logo} alt="logo" />
            </div>
            <LoginForm submitCallback={this.handleSubmit} />
          </CardContent>
          <CardActions className={classes.buttonLocation}>
            <RemoteSubmitButton
              component={Button}
              form="loginForm"
              label="Login"
              color="primary"
              variant="outlined"
              data-tip
              data-for="login-tt"
            />
            {/* <LoginTooltip /> */}
            <div>
              Remember me?
              <Checkbox
                label="Remember me"
                onChange={this.handleRememberMe}
                classes={{
                  root: classes.checkbox,
                  checked: classes.checked
                }}
              />
            </div>
          </CardActions>
          {invalidate ? (
            <div className={classes.footerText}>Login Failed</div>
          ) : (
            <div></div>
          )}
        </Card>
      </div>
    );
  }
}

LoginCard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    invalidate: state.authentication.invalidate
  };
};

export default connect(mapStateToProps)(withStyles(loginFormStyles)(LoginCard));
