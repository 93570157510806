import * as Types from "./types";
import { getAvailableTarget } from "../../utils/generalUtils";

export const flowchartReducer = (
  state = {
    tree: [],
    searchString: undefined,
    saved: true,
    previousString: undefined
  },
  action
) => {
  switch (action.type) {
    case Types.UPDATE_TREE:
      var availableTargetList = [];
      for (var i = 0; i < action.payload.length; i++) {
        var tempList = getAvailableTarget(action.payload[i], false)
        for (var j = 0; j < tempList.length; j++) {
          availableTargetList.push(tempList[j]);
        }
      }
      if (state.searchString === state.previousString) {
        return {
          ...state,
          tree: action.payload,
          availableTarget: availableTargetList,
          saved: false
        };
      } else {
        return {
          ...state,
          previousString: state.searchString,
          availableTarget: availableTargetList,
          saved: true
        };
      }
    case Types.SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload,
        sessionPath: null,
        pathError: null
      };
    case Types.SEARCH_DECREMENT:
    case Types.SEARCH_INCREMENT:
      return {
        ...state,
        searchFocusIndex: action.payload
      };
    case Types.NEW_FLOWCHART:
      return {
        ...state,
        tree: action.payload.newTree,
        metadata: action.payload.metadata,
        root: action.payload.root,
        availableTarget: action.payload.availableTarget,
        saved: true
      };
    case Types.LOAD_TREE:
      return {
        ...state,
        tree: action.payload.newTree,
        metadata: action.payload.metadata,
        root: action.payload.root,
        availableTarget: action.payload.availableTarget,
        saved: true
      };
    case Types.SEARCH_CALLBACK:
      return {
        ...state,
        ...action.payload
      };
    case Types.MODIFY_FLOWCHART:
      return {
        ...state,
        saved: false
      };
    case Types.SAVE_FLOWCHART:
      return {
        ...state,
        saved: true
      };
    case Types.SESSION_PATH_REQUEST:
      return {
        ...state,
        searchString: action.payload,
        pathError: null
      };
    case Types.SESSION_PATH_SUCCESS:
      return {
        ...state,
        sessionPath: action.payload,
        pathError: null
      };
    case Types.SESSION_PATH_FAILURE:
      return {
        ...state,
        sessionPath: null,
        pathError: action.payload
      };
    case Types.SESSION_RESET_SUCCESS:
      return {
        ...state,
        sessionError: false,
        sessionSuccess: true,
        sessionIdle: false
      };
    case Types.SESSION_RESET_FAILURE:
      return {
        ...state,
        sessionError: true,
        sessionSuccess: false,
        sessionIdle: false
      };
    case Types.SESSION_RESET_IDLE:
      return {
        ...state,
        sessionIdle: true
      };
    default:
      return state;
  }
};
