export const SET_SEARCH_STRING = 'SET_SEARCH_STRING'
export const MENU_SEARCH_INCREMENT = 'MENU_SEARCH_INCREMENT'
export const MENU_SEARCH_DECREMENT = 'MENU_SEARCH_DECREMENT'
export const SEARCH_CALLBACK = 'SEARCH_CALLBACK'

export const LOAD_MENU_TREE = 'LOAD_MENU_TREE'

export const MENU_EDIT_TOGGLED = 'MENU_EDIT_TOGGLED'

export const SAVE_MENU_TREE_SUCCESS = 'SAVE_MENU_TREE_SUCCESS'
export const UPDATE_MENU_TREE = 'UPDATE_MENU_TREE'

export const NEW_MENU_FOLDER = 'NEW_MENU_FOLDER'
export const DELETE_MENU_FOLDER_SUCCESS = 'DELETE_MENU_FOLDER_SUCCESS'

export const LOGOUT = 'USER_LOGOUT'