import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { getConfiguration } from "../reducers";
import WrapperTable from "../WrapperTable";
import Paper from "@material-ui/core/Paper";
import RemoteSubmitButton from "../RemoteSubmitButton";
import { putConfiguration } from "../reducers";
import { Dialog } from "material-ui";
import ConfigurationEditForm from "./ConfigurationEditForm";
import { configurationStyles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { confirmAlert } from "react-confirm-alert";

class Configuration extends Component {
  constructor(props) {
    super(props);

    this.state = { openEdit: false, selectedConfiguration: null,configs : null };

    this.handleOpenEdit = this.handleOpenEdit.bind(this);
    this.handleCloseEdit = this.handleCloseEdit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.filterConfigs = this.filterConfigs.bind(this);

  }

  componentWillMount() {
    this.props.dispatch(getConfiguration());
   
  }
  
  filterConfigs(data){

    var newData =[] ; 
    for(var i = 0 ; i<data.length ; i++){
      var temp = data[i].key
      //dont show sqs related configurations
      if(temp.includes("sqs|") ||temp.includes("courier|password")||temp.includes("blacklist|control")){
        console.log(temp);

        continue;
      }else{
        newData.push(data[i]);
      }
    }

  return newData;

  }

  handleOpenEdit(e) {
    if (e.rowData.key != "courier|provider") {
      this.setState({ openEdit: true, selectedConfiguration: e.rowData });
    } else {
      confirmAlert({
        title: "",
        message: "You cannot change provider",
        buttons: [
          {
            label: "OK"
          }
        ]
      });
    }
  }

  handleCloseEdit() {
    this.setState({ openEdit: false });
  }

  handleSubmit(form) {
    this.props.dispatch(putConfiguration(form));
    this.setState({ openEdit: false });
  }

  render() {

    var { data, classes } = this.props;

    data = this.filterConfigs(data);

    return (
      <div style={{ paddingRight: "15%" }}>
        <Grid container>
          <Grid item xs={12}>
            <Paper style={{ height: 600, width: "100%" }}>
              <WrapperTable
                rowCount={data.length}
                rowGetter={({ index }) => data[index]}
                onRowDoubleClick={e => this.handleOpenEdit(e)}
                rowHeight={56}
                headerHeight={56}
                columns={[
                  {
                    width: 500,
                    flexGrow: 1.0,
                    label: "Configuration Name",
                    dataKey: "key"
                  },
                  {
                    width: 500,
                    flexGrow: 1.0,
                    label: "Configuration Value",
                    dataKey: "value"
                  }
                ]}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Dialog
              title="Edit Configuration"
              actions={[
                <Button
                  variant="outlined"
                  onClick={this.handleCloseEdit}
                  className={classes.button}
                >
                  Cancel
                </Button>,
                <RemoteSubmitButton
                  component={Button}
                  form="configurationEditForm"
                  label="Submit"
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                />
              ]}
              open={this.state.openEdit}
              onRequestClose={this.handleCloseEdit}
              autoScrollBodyContent={true}
              contentStyle={{
                position: "absolute",
                left: "50%",
                top: "50%",
                height: "80%",
                transform: "translate(-50%, -50%)"
              }}
            >
              <ConfigurationEditForm
                submitCallback={this.handleSubmit}
                initialValues={this.state.selectedConfiguration}
              />
            </Dialog>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Configuration.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    data: state.configuration.configurations
  };
};

export default connect(mapStateToProps)(
  withStyles(configurationStyles)(Configuration)
);
