import * as Types from "./types";

export const monitoringReducer = (
  state = {
    inboundTransactions: null,
    webhooks: [],
    outboundTransactions: [],
    overallStatus: null
  },
  action
) => {
  switch (action.type) {
    case Types.MONITORING_REQUEST:
      return { ...state, loading: true };
    case Types.MONITORING_SUCCESS:
      return {
        loading: false,
        inboundTransactions: action.payload.inboundTransactions,
        webhooks: action.payload.webhooks,
        outboundTransactions: action.payload.outboundTransactions,
        overallStatus: action.payload.overallStatus
      };
    case Types.MONITORING_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};
