import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { tabStyles } from "./styles";
import HeaderBar from "../Header";
import Webhook from "./Webhook";
import AuthToken from "./AuthToken";
import CourierCredentials from "./CourierCredentials";
import Configuration from "../ConfigurationPage";
import CircularIndeterminate from "../CircularLoading";
import Sidebar from "../Sidebar";
import "../App.css";
import classnames from "classnames";

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = { value: 0 };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e, value) {
    this.setState({ value });
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    const title = "SMS Gateway - Settings";
    return (
      <div>
        <HeaderBar pageTitle={title} />
        {/* <div className={classes.block}></div> */}
        <Sidebar component="div" />
        <CircularIndeterminate isLoading={this.props.urlListIsLoading} />
        <div
          className={classnames("app-content", { "expanded-settings": true })}
        >
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
            >
              <Tab label="Webhooks" />
              {/* <Tab label='Customer Data'/> */}
              <Tab label="Authentication Token" />

              <Tab label="Courier Credentials" />
              <Tab label="Configuration" />
            </Tabs>
          </AppBar>
          {value === 0 && (
            <TabContainer
              component={Webhook}
              padding={classes.tabContainerPadding}
            />
          )}
          {/* {value === 1 && <TabContainer component={CustomerData} padding={classes.tabContainerPadding} />} */}
          {value === 1 && (
            <TabContainer
              component={AuthToken}
              padding={classes.tabContainerPadding}
            />
          )}
          {value === 2 && (
            <TabContainer
              component={CourierCredentials}
              padding={classes.tabContainerPadding}
            />
          )}

          {value === 3 && (
            <TabContainer
              component={Configuration}
              padding={classes.tabContainerPadding}
            />
          )}
        </div>
      </div>
    );
  }
}

const TabContainer = props => {
  const { component: Component, padding } = props;
  return (
    <div className={padding}>
      <Component {...props} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    urlListIsLoading: state.urlList.loading,
    data: state.configuration.configurations
  };
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

Settings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(withStyles(tabStyles)(Settings));
