import { createAction } from "redux-actions";
import { generateMenuTreeData, generateMenuTreePayload } from "../../utils/generalUtils";
import { getJwtToken } from "../../utils/generalUtils";
import * as Types from "./types";
import * as Cookies from "js-cookie";
import { API_VERSION } from "../../utils/Constants";


const forceLogout = createAction(Types.LOGOUT);

const loadMenuTree = createAction(Types.LOAD_MENU_TREE, menuPayload => generateMenuTreeData(menuPayload));

const updateMenuTreeData = createAction(Types.UPDATE_MENU_TREE);

const toggleMenuEdit = createAction(Types.MENU_EDIT_TOGGLED);

const saveMenuTreeSuccess = createAction(Types.SAVE_MENU_TREE_SUCCESS);

const saveMenuTree = menuTreeData => async dispatch => {

  const menuItemsPayload = generateMenuTreePayload(menuTreeData);

  try {
    const response = await fetch(API_VERSION + "menu", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getJwtToken()
      },
      body: JSON.stringify(menuItemsPayload)
    });

    if (response.status === 401 || response.status === 403) {
      const responseLogout = await fetch(API_VERSION + "session", {
        method: "DELETE",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" }
      });

      const body = await responseLogout.json();

      if (body.ok) {
        dispatch(forceLogout());
        Cookies.remove("JESSIONID");
        localStorage.clear();
        sessionStorage.clear();
      }
    } else {
      const body = await response.json();

      if (body.success) {
        delete body['success'];
        dispatch(saveMenuTreeSuccess(generateMenuTreeData(body)));
      }
    }

  } catch (err) {
    console.log(err);
  }
};

const newMenuFolder = (folderName, menuTreeData) => async dispatch => {
  const newFolder = { menuItemId: null, folderId: null, createdTimestamp: null, title: folderName, isDirectory: true, expanded: false, children: [] }

  var newMenuTreeData = menuTreeData.slice();
  newMenuTreeData.unshift(newFolder);

  dispatch(saveMenuTree(newMenuTreeData));
};

const deleteMenuFolderSuccess = createAction(Types.DELETE_MENU_FOLDER_SUCCESS);

const deleteMenuFolder = rowInfo => async dispatch => {

  try {
    const response = await fetch(API_VERSION + "menu/" + rowInfo.node.menuItemId, {
      method: "DELETE",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getJwtToken()
      }
    });
    const body = await response.json();

    if (body.success) {
      delete body['success'];
      dispatch(deleteMenuFolderSuccess(generateMenuTreeData(body)));
    }

  } catch (err) {
    console.log(err);
  }
};

const fetchMenuItems = () => async dispatch => {
  try {
    const response = await fetch(API_VERSION + "menu", {
      method: "GET",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getJwtToken()
      }
    });
    const json = await response.json();
    dispatch(loadMenuTree(json));
  } catch (err) {
    console.log(err);
  }
};

const postMenuItems = json => async dispatch => {

  const response = await fetch(API_VERSION + "menu", {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    },
    body: JSON.stringify(json)
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    const body = await response.json();
  } else {
    const body = await response.json();

    dispatch(loadMenuTree(body));
  }
};

const actions = {
  updateMenuTreeData,
  loadMenuTree,
  toggleMenuEdit,
  saveMenuTree,
  newMenuFolder,
  deleteMenuFolder,
  fetchMenuItems,
  postMenuItems
};

//Selectors
const getParsedMenuTree = state => state.treeData;
const getSearchIndex = state => state.searchFocusIndex;
const getSearchCount = state => state.searchFoundCount;
const getSearchString = state => state.searchString;
const getMenuEdit = state => state.menuEdit;

const selectors = {
  getParsedMenuTree,
  getSearchCount,
  getSearchIndex,
  getSearchString,
  getMenuEdit
};

export { actions, selectors };
