import {
  actions as flowchartActions,
  selectors as flowchartSelectors
} from "./flowcharts";
import {
  actions as menuActions,
  selectors as menuSelectors
} from "./menuItems";
import { actions as dbActions, selectors as dbSelectors } from "./db";
import _ from "lodash";
import { login, logout } from "./login";
import { getUsers, postUser, putUser, deleteUser } from "./users";
import { getRoles } from "./roles";
import {
  pingNewUrl,
  addUrl,
  deleteUrl,
  fetchUrlsWithRedux,
  testUrl,
  fetchTwilioCredentials,
  testTwilioCredentials,
  fetchAwsCredentials,
  fetchSinchCredentials,
  fetchTelusCredentials,
  urlActions
} from "./settings";

import {
  getNodeTemplates,
  postNodeTemplate,
  putNodeTemplate,
  deleteNodeTemplate,
  nodeTemplateActions
} from "./nodeTemplates";
import {
  getTemplates,
  postTemplate,
  putTemplate,
  deleteTemplate
} from "./templates";

import { refreshSession } from "./sessionRefresh";
import { getMonitoring } from "./monitoring";
import { getConfiguration, putConfiguration } from "./configuration";

import { getUserProfile, updateUserProfile, resetPassword } from "./profile";
import { generateReport } from "./reports";

import {
  fetchSessionsByDates,
  fetchTransactionErrorsByDates,
  fetchBlacklistsByDates,
  fetchWebhookErrorLogsByDates,
  fetchTransactionsByDates,
  fetchWebhookLogsByDates,
  fetchSessionTransactions
} from "./tables";

const wrapState = (name, selector) => state => selector(state[name]);
const wrapSelectors = (selectors, name) =>
  _.mapValues(selectors, selector => wrapState(name, selector));

const newFlowchartSelectors = wrapSelectors(flowchartSelectors, "flowcharts");
const newDBSelectors = wrapSelectors(dbSelectors, "db");
const newMenuSelectors = wrapSelectors(menuSelectors, "menu");

export {
  newFlowchartSelectors as flowchartSelectors,
  flowchartActions,
  newDBSelectors as dbSelectors,
  dbActions,
  newMenuSelectors as menuSelectors,
  menuActions,
  login,
  logout,
  getUsers,
  postUser,
  getRoles,
  putUser,
  deleteUser,
  pingNewUrl,
  addUrl,
  deleteUrl,
  fetchUrlsWithRedux,
  testUrl,
  fetchAwsCredentials,
  fetchTwilioCredentials,
  testTwilioCredentials,
  fetchSinchCredentials,
  fetchTelusCredentials,
  urlActions,
  getNodeTemplates,
  postNodeTemplate,
  putNodeTemplate,
  deleteNodeTemplate,
  nodeTemplateActions,
  getTemplates,
  postTemplate,
  putTemplate,
  deleteTemplate,
  refreshSession,
  getConfiguration,
  putConfiguration,
  getUserProfile,
  updateUserProfile,
  resetPassword,
  generateReport,
  fetchTransactionsByDates,
  fetchSessionsByDates,
  fetchTransactionErrorsByDates,
  fetchBlacklistsByDates,
  fetchWebhookErrorLogsByDates,
  fetchWebhookLogsByDates,
  fetchSessionTransactions,
  getMonitoring
};
