import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { sessionStyles } from './styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'

class SessionTimeout extends Component{

  render(){
    const { classes, openNotification, handleContinue, handleLogout } = this.props
    
    return(
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={openNotification}
      >
        <DialogTitle>Session Timeout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your session has timed out. Please click Continue to continue your session
            or Logout to go back to the login page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            variant='outlined'
            onClick={handleContinue}
            className={classes.Button}
          >
            Continue
          </Button>
          <Button 
            variant='outlined'
            className={classes.Button}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

SessionTimeout.propTypes = {
    classes: PropTypes.object.isRequired,
    openNotification: PropTypes.bool.isRequired,
    handleContinue: PropTypes.func.isRequired,
    handleLogout: PropTypes.func.isRequired
}

export default withStyles(sessionStyles)(SessionTimeout)